import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ElRefDirective } from '@app/_directives/el-ref/el-ref.directive';
import { ObserveFormGroupErrors, useFormErrorObservable } from '@app/_helpers/get-error-observable';
import { pushError } from '@app/_helpers/globalErrorHandler';
import { ErrorResponse, isWorkingHoursError, parseErrorResponse } from '@app/_helpers/is-error-object';
import parseSubscriptionAsStatus from '@app/_helpers/parseSubscriptionAsStatus';
import { hasPermissionByKey, isAdmin, isSupervisor } from '@app/_helpers/permission';
import {
  DEFAULT_PERMISSION_GROUPS,
  coerceTimeFormat,
  createRxValue,
  flattenEntityResponse,
  fromRxValue,
  getActiveSchedule,
  hasPermission,
  hoursToFormat,
  nextTick,
  resolveRawArgs,
  stringify,
} from '@app/_helpers/utils';
import { CustomValidators } from '@app/_validators/custom-validators';
import { AppService } from '@app/app.service';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { TimeDatePickerConfig } from '@app/shared/time-date-picker/time-date-picker-config';
import { TimeDatePickerComponent } from '@app/shared/time-date-picker/time-date-picker.component';
import { Order } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { addDays, format, isSameDay, isValid, startOfDay } from 'date-fns/esm';
import produce from 'immer';
import { clamp } from 'lodash-es';
import {
  combineLatest,
  combineLatestWith,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
import {
  ComegoQuery,
  ComegoService,
  ComegoTime,
  ComegoTimeType,
  Logger,
  MyTimesService,
  UserSettings,
  UserSettingsQuery,
  WorkingHourSettings,
  Workspace,
} from 'timeghost-api';
import { ComeGoTimeControl } from '../come-and-go-create-dialog/come-and-go-create-dialog.component';
import { COMEGO_ICON_MAP, COMEGO_MAX_DESCRIPTION } from '../come-and-go-create-dialog/come-and-go-utils';
import {
  UserSinglePickerDialogComponent,
  UserSinglePickerDialogData,
} from '../user-single-picker-dialog/user-single-picker-dialog.component';
export interface ComeGoUpdateDialogData {
  entity: ComegoTime;
  recording?: boolean;
  isPastTime?: boolean;
  handleRuleErrors?: boolean;
  error?: ErrorResponse;
}
type UpdateComeGoTimeControl = ComeGoTimeControl;
const log = new Logger('ComeAndGoUpdateDialogComponent');
@UntilDestroy()
@ObserveFormGroupErrors()
@Component({
  selector: 'tg-come-and-go-update-dialog',
  templateUrl: './come-and-go-update-dialog.component.html',
  styleUrls: ['./come-and-go-update-dialog.component.scss'],
})
export class ComeAndGoUpdateDialogComponent implements OnInit {
  readonly iconMap = COMEGO_ICON_MAP;
  readonly error$ = createRxValue<ErrorResponse>();
  readonly error$disabled = combineLatest([this.error$.asObservable(), this.userSettingsQuery.select()]).pipe(
    map(([err, user]) => {
      if (!err) return err;
      if (user.workspace?.schedules?.workspace?.workinghours?.preventDeviatingWorkingHours) return err;
      return {
        ...err,
        type: 'info',
      };
    }),
  );
  constructor(
    private ref: MatDialogRef<ComeAndGoUpdateDialogComponent>,
    private dialog: MatDialog,
    private userSettingsQuery: UserSettingsQuery,
    private myTimes: MyTimesService,
    private comego: ComegoService,
    private comegoQuery: ComegoQuery,
    private recordService: RecordToolbarService,
    private app: AppService,
    @Inject(MAT_DIALOG_DATA) private data: ComeGoUpdateDialogData,
  ) {}
  readonly isLoading = createRxValue(false);
  get isPastTime() {
    return this.data.isPastTime;
  }
  get handleRuleErrors() {
    return !!this.data?.handleRuleErrors;
  }
  readonly maxLength = COMEGO_MAX_DESCRIPTION;
  readonly group = new FormGroup(
    {
      name: new FormControl<string>(null, [Validators.maxLength(this.maxLength)]),
      user: new FormControl<Workspace['users'][0]>(null),
      start: new FormControl(null),
      end: new FormControl(null),
      date: new FormControl(new Date()),
      type: new FormControl<ComegoTimeType>('work'),
    },
    [
      CustomValidators.timeDiffCheck(
        'start',
        'end',
        false,
        (() => {
          const original = this.data.entity;

          return (key) => {
            if (!original) return null;
            if (key === 'start') return startOfDay(new Date(original.start));
            return startOfDay((original.end && new Date(original.end)) || new Date()); // running time - set to now
          };
        })(),
      ),
      // (ctrl) => {
      //   if (this.rulebreaks$.value) return this.rulebreaks$.value as {};
      //   return null;
      // },
    ],
  );
  readonly rulebreaks$ = fromRxValue<any | null>(
    combineLatest([this.group.valueChanges, this.userSettingsQuery.select()]).pipe(
      map(([value, user]) => {
        return null;
      }),
    ),
  );
  readonly duration$ = combineLatest([this.group.valueChanges, this.group.statusChanges]).pipe(
    startWith([this.group.value]),
    map(([x]) => {
      let acc = {
        type: x.type,
        sum: null as number,
      };
      const original = this.data.entity;
      if (!x.start || !x.end) return null;
      const start = coerceTimeFormat(x.start!, startOfDay(x.date)),
        end = coerceTimeFormat(x.end!, startOfDay(!original.end ? new Date() : x.date));
      if (end < start) return null;
      log.debug('duration: ', x.date, start, end, original);
      acc.sum = (end.getTime() - start.getTime()) / 1000;
      return acc;
    }),
  );
  readonly recording$ = combineLatest([
    this.duration$,
    timer(0, 1000).pipe(
      untilDestroyed(this),
      takeWhile(() => this.recording),
    ),
  ]).pipe(
    combineLatestWith(this.group.valueChanges),
    map(([, x]) => {
      if (!x) return null;
      let acc = {
        type: x.type,
        sum: null as number,
      };
      const original = this.data.entity;
      if (!x.start || !x.end) return null;
      const start = coerceTimeFormat(x.start!, startOfDay(x.date)),
        end = coerceTimeFormat(x.end!, startOfDay(!original.end ? new Date() : x.date));
      if (end < start) return null;
      log.debug('recording: ', x.date, start, end, original);
      acc.sum = (end.getTime() - start.getTime()) / 1000;
      return acc;
    }),
  );
  readonly workingHoursError$ = combineLatest([
    this.group.valueChanges,
    this.group.statusChanges,
    this.userSettingsQuery.select(),
  ]).pipe(
    startWith([this.group.getRawValue(), null, this.userSettingsQuery.getValue()] as [
      typeof this.group.value,
      any,
      UserSettings,
    ]),
    map(([, , _currentUser]) => {
      const value = this.group.getRawValue();
      if (!value?.date || !value.start || !value.end) return null;
      const user = { ..._currentUser, ...(value.user?.id && { id: value.user.id }) };
      if (!user?.workspace?.schedules?.workspace?.['workinghours']) return null;

      const times = this.comegoQuery.getAll({
        filterBy: (x) =>
          isSameDay(new Date(x.start), value.date!) &&
          x.user?.id === user.id &&
          (this.data.entity?.id ? x.id !== this.data.entity.id : true),
      });
      const tstart = coerceTimeFormat(value.start, value.date!),
        tend = coerceTimeFormat(value.end, value.date!);
      const sum = times.reduce(
        (acc, r) => {
          const start = new Date(r.start),
            end = (r.end && new Date(r.end)) || new Date();
          acc[r.type] += end.getTime() - start.getTime();
          return acc;
        },
        { absence: 0, pause: 0, work: 0 } as {
          [K in ComegoTimeType]: number;
        },
      );
      const valueDuration = tend.getTime() - tstart.getTime();
      sum[value.type] += clamp(valueDuration, 0, valueDuration);
      const wh: WorkingHourSettings = user.workspace.schedules.workspace['workinghours'];
      if (!wh) return null;
      const sumValue = sum.work;
      const pauseSum = sum.pause;
      const type = (!wh.preventDeviatingWorkingHours && 'info') || null;
      const ignoredCTypes = ['absence'] as ComegoTimeType[];
      const cTypeIgnored = ignoredCTypes.includes(value.type);
      if (type === 'info') return null;
      if (typeof wh.dailyMaxHours === 'number' && sum.work > wh.dailyMaxHours * 3600 * 1000 && !cTypeIgnored) {
        return {
          content: 'errors.times.comego.dailyMaxHours',
          args: resolveRawArgs({ dailyMaxHours: hoursToFormat(wh.dailyMaxHours) }),
          type,
        };
      }
      if (typeof wh.breakBetweenWorkingDays === 'number' && wh.breakBetweenWorkingDays > 0.0 && !cTypeIgnored) {
        const pastDay = addDays(value.date.getTime(), -1);
        const nextDay = addDays(value.date.getTime(), 1);
        const lastWorkingDayTime = this.comegoQuery.getAll({
          filterBy: (x) =>
            isSameDay(new Date(x.start), pastDay) &&
            x.user?.id === user.id &&
            x.type === 'work' &&
            (this.data.entity?.id ? x.id !== this.data.entity.id : true),
          limitTo: 1,
          sortBy(a, b) {
            const aend = new Date(a.end || new Date()),
              bend = new Date(b.end || new Date());
            return aend.getTime() - bend.getTime();
          },
          sortByOrder: Order.DESC,
        })?.[0];
        const nextWorkingDayTime = this.comegoQuery.getAll({
          filterBy: (x) =>
            isSameDay(new Date(x.start), nextDay) &&
            x.user?.id === user.id &&
            x.type === 'work' &&
            (this.data.entity?.id ? x.id !== this.data.entity.id : true),
          limitTo: 1,
          sortBy(a, b) {
            const atime = new Date(a.start || new Date()),
              btime = new Date(b.start || new Date());
            return atime.getTime() - btime.getTime();
          },
          sortByOrder: Order.ASC,
        })?.[0];
        const minBreakBetweenDay = wh.breakBetweenWorkingDays * 3600 * 1000;
        if (
          (lastWorkingDayTime?.end &&
            tstart.getTime() - new Date(lastWorkingDayTime.end ?? tstart.getTime()).getTime() < minBreakBetweenDay) ||
          (nextWorkingDayTime?.start &&
            new Date(nextWorkingDayTime.start ?? tend.getTime()).getTime() - tend.getTime() < minBreakBetweenDay)
        ) {
          return {
            content: 'errors.times.comego.breakBetweenWorkingDays',
            args: resolveRawArgs({ breakBetweenWorkingDays: wh.breakBetweenWorkingDays }),
            type,
          };
        }
      }
      if (wh.breaks?.length && !cTypeIgnored) {
        const breakRule = wh.breaks
          .slice()
          .sort((b, a) => a.workingHours - b.workingHours)
          .find((x) => {
            const workingHourMs = x.workingHours * 3600 * 1000;
            const pauseHourMs = x.minutesDue * 3600 * 1000;
            return workingHourMs < sumValue && pauseHourMs > pauseSum;
          });
        if (breakRule)
          return {
            content: 'errors.times.comego.breakRuleNotice',
            args: resolveRawArgs(breakRule),
            type,
          };
      }
      return null;
    }),
    tap((x) => log.debug('wh errors', x)),
  );
  get groupValue() {
    return this.group.getRawValue();
  }
  readonly workspace$isAdmin = fromRxValue(
    this.userSettingsQuery.select((x) => hasPermission(DEFAULT_PERMISSION_GROUPS.Admin, x)),
  );
  readonly workspace$canManageOthers = fromRxValue(this.userSettingsQuery.select((x) => isAdmin(x) || isSupervisor(x)));
  readonly groupError$ = combineLatest([this.group.statusChanges, this.group.valueChanges]).pipe(
    map(() => this.group.errors),
    tap((err) => log.debug('errors', err)),
  );
  get recording() {
    return this.data.recording || !this.data.entity.end;
  }

  getErrorObservable(controlName: string) {
    const aliasMap = {
      name: 'time.name',
      start: 'timer.time.start',
      end: 'timer.time.end',
    };
    const aliasName = aliasMap[controlName] || controlName;
    return useFormErrorObservable(this)(
      controlName,
      () => this.group.controls[controlName],
      {
        required: (error, ctrl) => {
          if (controlName === 'task') return { content: 'errors.record.desc-required', args: {} };
          if (controlName === 'project') return { content: 'errors.record.project-req', args: {} };
          return {
            content: 'errors.required',
            args: { field: aliasName },
          };
        },
        minlength: (error, ctrl) => ({
          content: 'errors.minlength',
          args: { field: aliasName, length: error.requiredLength },
        }),
        maxlength: (error, ctrl) => ({
          content: 'errors.maxlength',
          args: { field: aliasName, length: error.requiredLength },
        }),
        same: (error, ctrl) => {
          const aliasFields = Object.entries(ctrl.errors?.fields || {}).reduce(
            (acc, [key, value]) => ({ ...acc, [value as string]: aliasMap[value as string] }),
            {},
          );
          return { content: 'errors.sameValue', args: { ...aliasFields } };
        },
        range: (error, ctrl) => {
          return { content: 'errors.times.comego.intervening', args: {} };
        },
        sameType: (error, ctrl) => {
          return { content: 'Subsequent entries must not be the same type', args: {} };
        },
        missingType: (error, ctrl) => {
          return { content: 'Entries are missing a stop entry', args: {} };
        },
        invalidType: (error, ctrl) => {
          return { content: 'Before last type must not be of type "pause"', args: {} };
        },
        intervening: (error, ctrl) => {
          return { content: 'errors.times.comego.intervening', args: {} };
        },
      },
      (key) => key,
      {
        initialValidate: true,
      },
    );
  }
  readonly postDiffUser$ = combineLatest([
    this.group.valueChanges.pipe(
      map((x) => x?.user?.id),
      distinctUntilChanged(),
    ),
    this.userSettingsQuery.select(),
  ]).pipe(map(([uid, user]) => uid && user && uid !== user.id));
  private checkValueOnceChange(value: any) {
    this.group.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
        map(({ start, end }) => stringify({ start, end }) === stringify(value)),
        tap((x) => log.debug('break rule check', x)),
        takeWhile((x) => x, true),
      )
      .subscribe((x) => !x && this.error$.next(null));
  }
  readonly deletePerm$ = this.userSettingsQuery
    .select()
    .pipe(map((x) => hasPermissionByKey(x, 'groupsCanComegoCreateTimes' as any)));
  ngOnInit(): void {
    this.ref.updateSize('560px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    this.ref.disableClose = true;
    this.ref.keydownEvents().subscribe((k) => k.key === 'Escape' && this.group.pristine && this.ref.close());
    const user = this.userSettingsQuery.getValue();
    const time = this.data.entity,
      wstart = new Date(time.start),
      start = format(wstart, 'HH:mm'),
      end = format(new Date(time.end || Date.now()), 'HH:mm');
    this.group.setValue(
      {
        name: time.name ?? null,
        user: user.workspace.users.find((x) => x.id === this.data.entity.user.id),
        start,
        end,
        date: startOfDay(wstart),
        type: time.type,
      },
      { emitEvent: true },
    );
    if (this.recording) {
      this.group.controls.date.disable();
    }
    if (time.end)
      this.group.addValidators(
        CustomValidators.controlMustChangeWithMap(this.group.value, {
          user: (x) => x.id,
        }),
      );
    this.group.updateValueAndValidity();
    this.group.markAllAsTouched();
    nextTick(() => {
      this.group.patchValue(this.group.getRawValue()); // trigger form group subscriptions
      if (this.data?.error) {
        this.error$.next(this.data.error);
        this.checkValueOnceChange(this.group.value);
      }
    });
  }
  readonly currentDateHasSchedule$ = combineLatest([this.group.valueChanges, this.userSettingsQuery.select()]).pipe(
    map(([value, user]) => {
      if (!value?.date) return null;
      const sched = getActiveSchedule({ ...user, id: value.user?.id || user.id }, value.date);
      return sched?.enabled && !!sched.items.find((x) => x.day === startOfDay(value.date).getUTCDay() && x.enabled);
    }),
  );
  openUserPicker() {
    const user = this.group.value?.user;
    if (!user) return;
    this.dialog
      .open(UserSinglePickerDialogComponent, {
        data: <UserSinglePickerDialogData>{
          selected: this.group.value?.user,
          filter: ({ selected, ...x }) => {
            const status = ((user) => parseSubscriptionAsStatus(user.workspace, user))(
              this.userSettingsQuery.getValue(),
            );
            return ((x.has_license || status.isTrial) && !x.removed) || selected;
          },
        },
      })
      .afterClosed()
      .subscribe((user) => {
        if (user)
          this.patchValue({
            user,
          });
      });
  }
  patchValue(data: Partial<typeof this.group.value> = {}, options?: Parameters<typeof this.group.patchValue>[1]) {
    const user = this.userSettingsQuery.getValue();
    if (data.user === null) data.user = user.workspace.users.find((x) => x.id === user.id);
    return this.group.patchValue(data, options);
  }
  async submit() {
    const { start, end, user, type, date, name } = this.group.getRawValue();
    this.isLoading.update(true);
    const time = produce(this.data.entity, (draft) => {
      draft.name = name;
      draft.start = coerceTimeFormat(start, date).toISOString();
      if (this.recording) draft.end = new Date().toISOString();
      else draft.end = coerceTimeFormat(end, date).toISOString();
      draft.type = type;
      if (user) draft.user = user;
    });
    await this.comego
      .update(time)
      .then(this.recordService.handleWorkingHourSuccess.bind(this.recordService))
      .catch(async (err: HttpErrorResponse) => {
        this.isLoading.update(false);
        if (isWorkingHoursError(err.error)) {
          this.error$.next(parseErrorResponse(err));
          this.checkValueOnceChange({ start, end });
        } else {
          pushError(err);
        }
        return await Promise.reject(err);
      })
      .then(flattenEntityResponse)
      .then((x) => {
        if (x) this.comegoQuery.__store__.upsertMany(x);
        this.ref.close(x);
      });
    this.isLoading.update(false);
  }

  openCalPicker() {
    return this.dialog
      .open(TimeDatePickerComponent, {
        data: <TimeDatePickerConfig>{
          selectedDate: new Date(this.group.value.date),
        },
      })
      .afterClosed()
      .pipe(filter((x) => x && isValid(x)))
      .subscribe((x) => {
        this.patchValue({
          date: x,
        });
      });
  }
  async delete(confirmDeleteTemplate?: TemplateRef<any>) {
    this.isLoading.next(true);
    this.group.disable();
    if (confirmDeleteTemplate) {
      const allowDelete = await this.dialog
        .open(confirmDeleteTemplate, {
          data: {
            entity: this.data,
          },
        })
        .afterClosed()
        .toPromise()
        .then((x) => x === true)
        .catch(() => false);
      if (!allowDelete) {
        this.isLoading.next(false);
        this.group.enable();
        return;
      }
    }
    const entityResult = { ...this.data.entity, deleted: true };
    await this.comego.delete(this.data.entity as any);
    this.ref.close([entityResult]);
  }
  selectInput(ev: Event, timeInput: ElRefDirective) {
    const el: HTMLElement = timeInput.elementRef.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }
}
