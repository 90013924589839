import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { WorkspaceUser } from '@app/_classes/timeghost';
import { ElRefDirective } from '@app/_directives/el-ref/el-ref.directive';
import { ObserveFormGroupErrors, useFormErrorObservable } from '@app/_helpers/get-error-observable';
import { pushError } from '@app/_helpers/globalErrorHandler';
import {
  ErrorResponse,
  isBreakRuleError,
  isWorkingHoursError,
  parseErrorResponse,
} from '@app/_helpers/is-error-object';
import parseSubscriptionAsStatus from '@app/_helpers/parseSubscriptionAsStatus';
import { isAdmin, isSupervisor } from '@app/_helpers/permission';
import {
  DEFAULT_PERMISSION_GROUPS,
  coerceTimeFormat,
  createRxValue,
  distinctUntilChangedJson,
  fromRxValue,
  getActiveSchedule,
  hasPermission,
  nextTick,
  stringify,
} from '@app/_helpers/utils';
import { AppService } from '@app/app.service';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { TimeDatePickerConfig } from '@app/shared/time-date-picker/time-date-picker-config';
import { TimeDatePickerComponent } from '@app/shared/time-date-picker/time-date-picker.component';
import { Order } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  addDays,
  addMilliseconds,
  addMinutes,
  clamp as clampDate,
  endOfDay,
  format,
  isSameDay,
  isValid,
  roundToNearestMinutes,
  startOfDay,
} from 'date-fns/esm';
import { pick } from 'lodash-es';
import { combineLatest, distinctUntilChanged, filter, map, startWith, take, takeWhile, tap } from 'rxjs';
import { firstBy } from 'thenby';
import {
  ComegoQuery,
  ComegoService,
  ComegoTime,
  ComegoTimeType,
  Logger,
  UserSettingsQuery,
  Workspace,
} from 'timeghost-api';
import {
  ComeGoTimeControl,
  checkTimeOrder,
  generateTimeTableFromSchedule,
} from '../come-and-go-create-dialog/come-and-go-create-dialog.component';
import { COMEGO_ICON_MAP, COMEGO_MAX_DESCRIPTION } from '../come-and-go-create-dialog/come-and-go-utils';
import {
  UserSinglePickerDialogComponent,
  UserSinglePickerDialogData,
} from '../user-single-picker-dialog/user-single-picker-dialog.component';

type TimesSingleControl = ComeGoTimeControl & { recording: FormControl<boolean> };
type TimesControl = FormGroup<TimesSingleControl>;
const log = new Logger('ComeAndGoMultiDialogComponent');
export interface WorkingHoursUpdateData {
  time?: {
    value: ComegoTime[];
    correctTimeRanges?: boolean;
    lockItem?: boolean;
    refetchFromDate?: boolean;
    date: Date;
  };
  error?: ErrorResponse;
  hiddenFields?: string[];
  disabledFields?: string[];
  allowedTimeTypes?: ComegoTimeType[];
  enableNextDayNavigation?: boolean;
  disabled?: boolean;
}
@UntilDestroy()
@ObserveFormGroupErrors()
@Component({
  selector: 'tg-come-and-go-day-update-dialog',
  templateUrl: './come-and-go-update-dialog.component.html',
  styleUrls: ['./come-and-go-update-dialog.component.scss'],
})
export class ComeAndGoMultiUpdateDialogComponent implements OnInit, AfterViewInit {
  readonly iconMap = COMEGO_ICON_MAP;
  readonly error$ = createRxValue<ErrorResponse>();
  constructor(
    private ref: MatDialogRef<ComeAndGoMultiUpdateDialogComponent>,
    private dialog: MatDialog,
    private userSettingsQuery: UserSettingsQuery,
    private app: AppService,
    private comegoService: ComegoService,
    private comegoQuery: ComegoQuery,
    private recordService: RecordToolbarService,
    @Inject(MAT_DIALOG_DATA)
    private data: WorkingHoursUpdateData,
  ) {
    if (!this.data) this.data = {};
  }
  readonly isLoading = createRxValue(false);
  readonly maxLength = COMEGO_MAX_DESCRIPTION;
  get enableNextDayNavigation() {
    return !!this.data.enableNextDayNavigation;
  }
  readonly group = new FormGroup({
    name: new FormControl<string>(null, [Validators.maxLength(this.maxLength)]),
    user: new FormControl<Workspace['users'][0]>(null),
    times: new FormArray<TimesControl>([], {
      validators: [
        checkTimeOrder(
          () => this.userSettingsQuery.getValue(),
          () => {
            const date = this.group?.getRawValue()?.date as Date;
            if (!date || !isValid(date)) return null;
            return {
              absence: 0,
              pause: 0,
              work: 0,
            } as { [K in ComegoTimeType]: number };
          },
          () => {
            const { date } = this.group?.getRawValue() || {};
            if (!date || !isValid(date)) return null;
            const currentUser = this.group?.getRawValue()?.user as WorkspaceUser;
            const user = {
              ...this.userSettingsQuery.getValue(),
              ...((currentUser?.id && { id: currentUser?.id }) || {}),
            };
            const pastDay = addDays(date.getTime(), -1);
            const lastWorkingDayTime = this.comegoQuery.getAll({
              filterBy: (x) => isSameDay(new Date(x.start), pastDay) && x.user?.id === user.id,
              limitTo: 1,
              sortBy(a, b) {
                const aend = new Date(a.end || new Date()),
                  bend = new Date(b.end || new Date());
                return aend.getTime() - bend.getTime();
              },
              sortByOrder: Order.DESC,
            })?.[0];
            if (!lastWorkingDayTime) return null;
            return {
              ...lastWorkingDayTime,
              start: new Date(lastWorkingDayTime.start),
              end: new Date(lastWorkingDayTime.end || new Date()),
            };
          },
          () => {
            const { date } = this.group?.getRawValue() || {};
            if (!date || !isValid(date)) return null;
            const user = this.userSettingsQuery.getValue();
            const dayOfTime = addDays(date.getTime(), 1);
            const nextWorkingDayTime = this.comegoQuery.getAll({
              filterBy: (x) => isSameDay(new Date(x.start), dayOfTime) && x.user?.id === user.id,
              limitTo: 1,
              sortBy(a, b) {
                const atime = new Date(a.start || new Date()),
                  btime = new Date(b.start || new Date());
                return atime.getTime() - btime.getTime();
              },
              sortByOrder: Order.ASC,
            })?.[0];
            if (!nextWorkingDayTime) return null;
            return {
              ...nextWorkingDayTime,
              start: new Date(nextWorkingDayTime.start),
              end: new Date(nextWorkingDayTime.end || new Date()),
            };
          },
        ),
        (ctrl) => {
          if (ctrl.value?.find((d: any) => d.recording)) {
            return {
              recording: true,
            };
          }
          return null;
        },
      ],
      updateOn: 'blur',
    }),
    date: new FormControl<Date>(new Date()),
  });
  readonly duration$ = this.group.valueChanges.pipe(
    startWith(this.group.getRawValue()),
    map(() => {
      const x = this.group.getRawValue();
      return x.times.reduce(
        (acc, r) => {
          if (!r.start || !r.end) return acc;
          const start = coerceTimeFormat(r.start!).getTime(),
            end = coerceTimeFormat(r.end!).getTime();
          if (end < start) return acc;
          const sum = (end - start) / 1000;
          if (r.type === 'work') acc.working += sum;
          else if (r.type === 'pause') acc.pause += sum;
          else if (r.type === 'absence') acc.absence += sum;
          return acc;
        },
        { working: 0, pause: 0, absence: 0 },
      );
    }),
  );
  readonly workspace$isAdmin = fromRxValue(
    this.userSettingsQuery.select((x) => hasPermission(DEFAULT_PERMISSION_GROUPS.Admin, x)),
  );
  readonly workspace$canManageOthers = fromRxValue(this.userSettingsQuery.select((x) => isAdmin(x) || isSupervisor(x)));
  getErrorObservable(controlName: string) {
    const aliasMap = {
      name: 'time.name',
      start: 'timer.time.start',
      end: 'timer.time.end',
    };
    const aliasName = aliasMap[controlName] || controlName;
    return useFormErrorObservable(this, { respectState: true })(
      controlName,
      () => this.group.controls[controlName],
      {
        required: (error, ctrl) => {
          if (controlName === 'task') return { content: 'errors.record.desc-required', args: {} };
          if (controlName === 'project') return { content: 'errors.record.project-req', args: {} };
          if (controlName === 'times') return { content: 'errors.times.comego.required', args: {} };
          return {
            content: 'errors.required',
            args: { field: aliasName },
          };
        },
        same: (error, ctrl) => {
          const aliasFields = Object.entries(ctrl.errors?.fields || {}).reduce(
            (acc, [key, value]) => ({ ...acc, [key]: aliasMap[value as string] }),
            {},
          );
          return { content: 'errors.sameValue', args: { ...aliasFields } };
        },
        breakRule: (error) => {
          if (error.type === 'break')
            return { content: 'errors.times.comego.breakRuleNotice', args: pick(error, 'workingHours', 'minutesDue') };
          if (error.type === 'rest')
            return {
              content: 'errors.times.comego.breakBetweenWorkingDays',
              args: { breakBetweenWorkingDays: error.value },
            };
          if (error.type === 'maxHours')
            return { content: 'errors.times.comego.dailyMaxHours', args: { dailyMaxHours: error.value } };
          return null;
        },
        minlength: (error, ctrl) => ({
          content: 'errors.minlength',
          args: { field: aliasName, length: error.requiredLength },
        }),
        maxlength: (error, ctrl) => ({
          content: 'errors.maxlength',
          args: { field: aliasName, length: error.requiredLength },
        }),
        sameType: (error, ctrl) => {
          return { content: 'Subsequent entries must not be the same type', args: {} };
        },
        missingType: (error, ctrl) => {
          return { content: 'Entries are missing a stop entry', args: {} };
        },
        invalidType: (error, ctrl) => {
          return { content: 'Before last type must not be of type "pause"', args: {} };
        },
        intervening: (error, ctrl) => {
          return { content: 'errors.times.comego.intervening', args: {} };
        },
      },
      (key) => key,
      {
        initialValidate: false,
      },
    );
  }
  readonly postDiffUser$ = combineLatest([
    this.group.valueChanges.pipe(
      map((x) => x?.user?.id),
      distinctUntilChanged(),
    ),
    this.userSettingsQuery.select(),
  ]).pipe(map(([uid, user]) => uid && uid !== user.id));

  readonly currentDateHasSchedule$ = combineLatest([this.group.valueChanges, this.userSettingsQuery.select()]).pipe(
    map(([value, user]) => {
      const { date } = this.group.getRawValue() ?? {};
      if (!date) return null;
      const sched = getActiveSchedule({ ...user, id: value.user?.id || user.id }, date);
      return sched?.enabled && !!sched.items.find((x) => x.day === startOfDay(date).getUTCDay() && x.enabled);
    }),
  );
  readonly hiddenFields = createRxValue<{ [key: string]: boolean }>({});
  readonly canAddTime = createRxValue(true);
  readonly enabledTypes = createRxValue<{ [K in ComegoTimeType]?: boolean }>({
    absence: true,
    pause: true,
    work: true,
  });
  async ngOnInit() {
    this.ref.updateSize('560px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    const now = new Date();
    const userSettings = this.userSettingsQuery.getValue();
    const userFromTime = this.data.time?.value?.find((d) => d.user)?.user ?? { id: userSettings.id };
    const user = userSettings.workspace.users?.find((d) => d.id === userFromTime.id) as WorkspaceUser;
    if (this.data.time.refetchFromDate && user) {
      const start = startOfDay(this.data.time.date),
        end = endOfDay(start);
      this.isLoading.next(true);
      this.data.time.value = await this.comegoService.adapter
        .post<any>('/get/comego', {
          $filter: `start le '${end.toISOString()}' and start ge '${start.toISOString()}' and user__id eq '${user.id}'`,
        })
        .toPromise()
        .finally(() => {
          this.isLoading.next(false);
        });
    }
    this.group.patchValue({
      user,
      date: this.data.time?.date ?? now,
    });
    if (this.data?.hiddenFields?.length)
      this.hiddenFields.next(this.data.hiddenFields.reduce((acc, r) => ({ ...acc, [r]: true }), {}));
    this.group.updateValueAndValidity();
    let error: any = this.data.error;
    if (isBreakRuleError(error)) pushError(error.message, error.args);
    error = null;

    // auto generate time ranges based on time values
    nextTick(() => {
      this.data.time.value?.forEach((t) => {
        const start = new Date(t.start);
        this.addTimeRange(
          t.type,
          start,
          new Date(t.end ?? (!isSameDay(start, now) ? endOfDay(start) : now)),
          false,
          !t.end,
        );
      });
      this.group.controls.times.updateValueAndValidity();
    });

    // validate and check errors on next tick, updates ui instantly
    if (error) {
      nextTick(() => {
        this.error$.next(error);
        if (!this.data.disabled && isWorkingHoursError(error)) this.checkValueOnceChange(this.group.value.times);
        if (this.data.disabled) {
          this.group.markAsPristine();
          this.group.disable();
        }
      });
    }
    if (this.data.enableNextDayNavigation) {
      this.group.controls.date.disable();
    }
    if (!this.data.disabled && this.data.disabledFields?.length)
      nextTick(() => {
        this.data.disabledFields.forEach((x) => {
          if (this.group.controls[x]?.enabled) {
            this.group.controls[x].disable();
          } else {
            if (x === 'time.type') this.group.controls.times.controls.forEach((t) => t.controls.type.disable());
            if (x === 'time') {
              this.group.controls.times.controls.forEach((t) => t.disable());
              this.group.controls.times.disable();
            }
            if (x === 'time.add') this.canAddTime.next(false);
          }
        });
      });

    if (this.data.allowedTimeTypes?.length) {
      this.enabledTypes.next(this.data.allowedTimeTypes.reduce((acc, r) => ({ ...acc, [r]: true }), {}));
    }
    this.group.valueChanges
      .pipe(
        untilDestroyed(this),
        map((x) => x?.date),
        distinctUntilChangedJson(),
      )
      .subscribe(() => {
        this.group.controls.times.updateValueAndValidity();
      });
    this.group.valueChanges.pipe(take(1)).subscribe(() => {
      this.sortTimes(true);
      this.group.setMustChange(this.group.getRawValue());
    });
  }
  ngAfterViewInit(): void {}
  // private sortingState = false;
  blurFormSort(ev: Event) {
    if (this.group.pristine || this.group.controls.times.length < 2) return;
    const currentTarget = ev.currentTarget as HTMLElement;
    requestAnimationFrame(() => {
      if (currentTarget.contains(document.activeElement)) return;
      this.sortTimes();
      // if (!this.sortingState) {
      // this.sortingState = true;
      // currentTarget.focus();
      // log.debug({ sortingEventSource: currentTarget });
      // setTimeout(() => {
      //   this.sortingState = false;
      // }, 100);
      // }
    });
  }
  private sortTimes(force: boolean = false) {
    if (!force && (this.group.pristine || this.group.controls.times.length < 2)) return;
    const { times } = this.group.getRawValue();
    this.group.patchValue({
      times: times.sort(
        firstBy((d: any) => coerceTimeFormat(d.start).getTime(), 'asc').thenBy(
          (d) => coerceTimeFormat(d.end ?? d.start).getTime(),
          'asc',
        ),
      ),
    });
    // todo - sorted clone to controls to kind of fix focus issue on re-sort
    // this.group.controls.times.controls = this.group.controls.times.controls.toSorted(
    //   firstBy((d: any) => coerceTimeFormat(d.value.start).getTime(), 'asc').thenBy(
    //     (d) => coerceTimeFormat(d.value.end ?? d.value.start).getTime(),
    //     'asc',
    //   ),
    // );
    log.debug({ sortedTimes: times });
    this.group.updateValueAndValidity();
  }
  resetToSchedule() {
    const { date, user: selectedUser } = this.group.value;
    const user = this.userSettingsQuery.getValue();
    const times = generateTimeTableFromSchedule({ ...user, id: (selectedUser || user).id }, date);
    this.group.controls.times.clear({ emitEvent: true });
    this.group.patchValue({
      times: [],
    });
    if (times)
      times.forEach((x) =>
        this.group.controls.times.push(
          new FormGroup({
            type: new FormControl(x.type || 'work'),
            start: new FormControl(x.start!),
            end: new FormControl(x.end!),
            recording: new FormControl(!x.end),
            user: new FormControl(null),
          }),
        ),
      );
    this.group.updateValueAndValidity();
  }
  openUserPicker() {
    const user = this.group.value?.user;
    if (!user) return;
    this.dialog
      .open(UserSinglePickerDialogComponent, {
        data: <UserSinglePickerDialogData>{
          selected: this.group.value?.user,
          filter: ({ selected, ...x }) => {
            const status = ((user) => parseSubscriptionAsStatus(user.workspace, user))(
              this.userSettingsQuery.getValue(),
            );
            return ((x.has_license || status.isTrial) && !x.removed) || selected;
          },
        },
      })
      .afterClosed()
      .subscribe((user) => {
        if (user)
          this.patchValue({
            user,
          });
      });
  }
  calculateDuration(ctrl: TimesControl, ctrlIndex: number) {
    const value = ctrl.getRawValue();
    if (!value) return null;
    const start = coerceTimeFormat(value.start!).getTime(),
      end = coerceTimeFormat(value.end!).getTime();
    if (end < start) return null;
    return (end - start) / 1000;
  }
  get groupValue() {
    return this.group.getRawValue();
  }
  addTimeRange(
    type: ComegoTimeType,
    start: Date,
    end: Date,
    emitChangesOnAdd: boolean = true,
    recording: boolean = false,
  ) {
    const isDisabledControl = (name: string) => !!this.data.disabledFields?.find((x) => x === name);
    this.group.controls.times.push(
      new FormGroup<TimesSingleControl>({
        start: new FormControl(format(start, 'HH:mm')),
        end: new FormControl(format(end, 'HH:mm')),
        type: new FormControl({ value: type, disabled: isDisabledControl('time.type') }),
        recording: new FormControl(recording),
        user: new FormControl(null),
      }),
      { emitEvent: false },
    );
    if (emitChangesOnAdd) this.group.controls.times.updateValueAndValidity();
  }
  addTime(type?: ComegoTimeType, duration?: number, reversi?: boolean) {
    const timeValues = this.group.controls.times.getRawValue();
    const prevControl = timeValues[timeValues.length - 1];
    const prevType = prevControl?.type || 'work';
    const isDisabledControl = (name: string) => !!this.data.disabledFields?.find((x) => x === name);
    const nextType = type ?? (!prevControl ? 'work' : prevType === 'work' ? 'pause' : 'work'),
      prevTime =
        (prevControl && coerceTimeFormat(prevControl?.end)) || roundToNearestMinutes(new Date(), { nearestTo: 15 }),
      nextTime =
        (duration &&
          clampDate(addMilliseconds(prevTime.getTime(), duration), { start: prevTime, end: endOfDay(prevTime) })) ||
        addMinutes(prevTime.getTime(), 30);
    if (this.data.allowedTimeTypes && !this.data.allowedTimeTypes?.find((x) => x === nextType)) return;
    if (reversi)
      this.group.controls.times.insert(
        0,
        new FormGroup<TimesSingleControl>({
          end: new FormControl(format(nextTime, 'HH:mm')),
          type: new FormControl({ value: nextType, disabled: isDisabledControl('time.type') }),
          start: new FormControl(format(prevTime, 'HH:mm')),
          recording: new FormControl(false),
          user: new FormControl(null),
        }),
        { emitEvent: false },
      );
    else
      this.group.controls.times.push(
        new FormGroup<TimesSingleControl>({
          end: new FormControl(format(nextTime, 'HH:mm')),
          type: new FormControl({ value: nextType, disabled: isDisabledControl('time.type') }),
          start: new FormControl(format(prevTime, 'HH:mm')),
          recording: new FormControl(false),
          user: new FormControl(null),
        }),
        { emitEvent: false },
      );
    this.group.controls.times.updateValueAndValidity();
  }
  deleteItem(index: number) {
    this.group.controls.times.removeAt(index);
    this.group.controls.times.controls.forEach((x) => x.updateValueAndValidity());
    this.group.updateValueAndValidity();
  }
  patchValue(data: Partial<typeof this.group.value> = {}, options?: Parameters<typeof this.group.patchValue>[1]) {
    const user = this.userSettingsQuery.getValue();
    if (data.user === null) data.user = user.workspace.users.find((x) => x.id === user.id);
    return this.group.patchValue(data, options);
  }
  private checkValueOnceChange(value: any) {
    this.group.valueChanges
      .pipe(
        untilDestroyed(this),
        map((x) => stringify(x.times) === stringify(value)),
        tap((x) => log.debug('break rule check', x)),
        takeWhile((x) => x, true),
      )
      .subscribe((x) => !x && this.error$.next(null));
  }
  isFieldShown(name: string) {
    return !this.hiddenFields.value?.[name];
  }
  async deleteTimes() {
    const ids = this.data.time.value?.map((d) => d.id).filter(Boolean) ?? [];
    if (!ids?.length) return;
    await this.comegoService.delete(...(ids.map((id) => ({ id })) as any));
  }
  async submit() {
    const { times, user, date: now, name } = this.group.getRawValue();
    if (!times.length) return;
    this.isLoading.update(true);
    const createTimes = times.reduce((acc, r) => {
      const start = coerceTimeFormat(r.start, now),
        end = coerceTimeFormat(r.end, now);
      acc.push({ ...r, start, end, user, name });
      return acc;
    }, []);
    let error: any;
    await this.deleteTimes().catch((err) => {
      error = err;
      this.isLoading.update(false);
      pushError(err);
    });
    if (error) return;
    await this.comegoService
      .add(...createTimes)
      .then(this.recordService.handleWorkingHourSuccess.bind(this.recordService))
      .catch(async (err: HttpErrorResponse) => {
        this.isLoading.update(false);
        if (isWorkingHoursError(err.error)) {
          this.error$.next(parseErrorResponse(err));
          this.checkValueOnceChange(times);
        } else {
          pushError(err);
        }
        return Promise.reject(err);
      })
      .then((times) => {
        this.ref.close(times);
      });
    this.isLoading.update(false);
  }

  openCalPicker() {
    return this.dialog
      .open(TimeDatePickerComponent, {
        data: <TimeDatePickerConfig>{
          selectedDate: new Date(this.group.getRawValue().date),
        },
      })
      .afterClosed()
      .pipe(filter((x) => x && isValid(x)))
      .subscribe((x) => {
        this.patchValue({
          date: x,
        });
      });
  }
  selectInput(ev: Event, timeInput: ElRefDirective) {
    const el: HTMLElement = timeInput.elementRef.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }
}
