import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { IdObject } from '@app/_classes/cosmos-entity';
import { isAdmin } from '@app/_helpers/permission';
import { UserSettingsQuery } from 'timeghost-api';

export default function SupervisePageGuard() {
  const user = inject(UserSettingsQuery).getValue();
  const router = inject(Router);

  if (user.workspace?.settings?.comego === false) return router.createUrlTree(['/']);

  if (isAdmin(user)) return true;
  return !!user.workspace.users?.find((u) => u?.['supervisors']?.find((s: IdObject) => s.id === user.id));
}
