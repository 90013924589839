import { IdArray, IdObject } from '@app/_classes/cosmos-entity';
import { Project, UserSettings, Workspace } from 'timeghost-api';
import { NoIndex } from './types';
import { userProjectTaskPerm } from './userProjectFind';
import { DEFAULT_PERMISSION_GROUPS, hasPermission, hasUserInProject } from './utils';

export function isAdmin(user: UserSettings) {
  return hasPermission(DEFAULT_PERMISSION_GROUPS.Admin, user);
}
export function isSupervisor(user: UserSettings) {
  return !!user.workspace?.users?.find((d) => d['supervisors']?.find((s: IdObject) => s.id === user.id));
}
type PMS = Workspace['permissionSettings'] & { groupsCanComegoExportTimes: IdArray };
type PMSKnown = keyof NoIndex<PMS>;
export function hasPermissionByKey(user: UserSettings, key: PMSKnown) {
  if (isAdmin(user)) return true;
  return !!user.workspace.permissionSettings[key]?.find((p: { id: string }) => hasPermission(p.id, user));
}
export function hasCreateTaskPermission(user: UserSettings, project: Project) {
  if (!project) return false;
  return userProjectTaskPerm(user, project);
}
export function hasCreateProjectPermission(user: UserSettings) {
  return hasPermissionByKey(user, 'groupsCanManageProjects');
}
export function isProjectUser(uid: string, workspace: Workspace, project: Project) {
  return hasUserInProject({ id: uid, workspace } as any, project);
}
